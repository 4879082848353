@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  scrollbar-color: #d84f4f #e66262;
  font-family: 'Merriweather', Rubik;
}

.header-link {
  @apply text-gray-800 text-lg font-medium relative cursor-pointer;
  text-decoration: none;
}

.header-link::after {
  content: '';
  @apply absolute bottom-0 left-0 w-1/2 h-0.5 bg-red-500 transition-all duration-300;
}

.header-link:hover::after {
  @apply w-full;
}

.header-button {
  @apply bg-red-600 text-white px-4 py-2 text-lg font-medium hover:bg-red-700 transition duration-300;
}
.curvy-bg-container {
  position: relative;
  overflow: hidden;
}

.curvy-bg-left,
.curvy-bg-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.curvy-bg-left {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23fef3c7' fill-opacity='0.4' d='M0,160L48,176C96,192,192,224,288,224C384,224,480,192,576,181.3C672,171,768,181,864,181.3C960,181,1056,171,1152,160C1248,149,1344,139,1392,133.3L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
}

.curvy-bg-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23fafafa' fill-opacity='0.4' d='M0,160L48,176C96,192,192,224,288,224C384,224,480,192,576,181.3C672,171,768,181,864,181.3C960,181,1056,171,1152,160C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
}
 

.thank-you-image {
  max-width: 90%;
  max-height: 280px;
}

@media (max-width: 640px) {
  .thank-you-image {
    max-width: "95%";
    max-height: "250px";
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .thank-you-image {
    max-width: "85%";
    max-height: "240px";
  }
}